/* Container for the contact page */
.contact-container {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 2s ease-in;
}

/* Title styling */
.contact-title {
  color: #1c718e;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Ysabeau', sans-serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  animation: bounce 2s infinite;
}

/* Subtitle styling */
.contact-subtitle {
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  padding: 10px 50px;
}

/* Form container styling */
.contact-form {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form control styling */
.contact-form .form-control {
  border-radius: 5px;
  border: 2px solid #1c718e;
  transition: border-color 0.3s;
}

/* Focused form control styling */
.contact-form .form-control:focus {
  border-color: #ff33a1;
  box-shadow: 0 0 0 0.2rem rgba(255, 33, 161, 0.25);
}

/* Button styling */
.contact-button {
  background-color: #1c718e;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

/* Button hover effect */
.contact-button:hover {
  background-color: #ff33a1;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for fade-out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Container for fading messages */
.messages {
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  height: 100px; /* Adjust based on your design */
  overflow: hidden;
  text-align: center;
  position: relative;
}

/* Individual message styling */
.message {
  position: absolute;
  width: 100%;
  color: #ff33a1; /* Hot pink color */
  font-family: 'Allura', cursive;
  font-size: 2rem; /* Adjust based on your design */
  opacity: 0;
  animation: fadeIn 3s ease-in-out, fadeOut 3s ease-in-out;
  display: flex;
  justify-content: center; /* Horizontally center text within the message */
  align-items: center; /* Vertically center text within the message */
}

/* Animation for the current message */
.fade-in {
  animation: fadeIn 3s ease-in-out forwards;
}

/* Animation for other messages */
.fade-out {
  animation: fadeOut 3s ease-in-out forwards;
}
