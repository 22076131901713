.card-custom {
  border: 10px groove #1c718e;
  width: 100%;
  max-width: 22rem; /* Adjusted for wider cards */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s, box-shadow 0.3s, z-index 0.3s;
  position: relative;
  z-index: 1;
}

.card-custom:hover {
  transform: scale(1.2); /* Scale up on hover */
  box-shadow: 0px 8px 30px rgba(255, 105, 180, 0.9); /* Pink shadow on hover */
  z-index: 10;
}

.card-img-custom {
  padding: 5px; /* Reduced padding to minimize space between image and text */
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Ensure image fits the card width */
}

.card-body-custom {
  padding: 10px; /* Adjust padding to reduce vertical spacing */
  height: auto; /* Adjust height to content */;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.card-title-custom {
  color: #1c718e;
  font-family: 'Ysabeau', sans-serif;
  margin-bottom: 2rem; /* Reduce space below the title */
}

.card-description {
  text-align: justify;
  font-size: .9rem;
  margin-bottom: 0; /* Reduce space below the description */
}




